<template>
  <div class="cishu_warp">
    <SubjectCard ref="SubjectCardRef"
                 @initList="getInfo" />

    <Mytable :tableData="info"
             :showIndex='true'
             :tableColumns="tableColumns"></Mytable>

    <div class="content"
         v-if="ruodianArr.length">
      <div class="title">
        训练次数小于2次的知识点
      </div>
      <div class="tabs">
        <div class="tab"
             v-for="(item,index) in ruodianArr"
             :key="index">
          {{item.knowledgeDesc}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SubjectCard from '@/views/components/subject_card/indexCp.vue'
import Mytable from '../components/table.vue'
import { getKnowledgeWeak } from '@/api/analysis.js'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      info: [],
      tableColumns: [
        { prop: 'knowledgeDesc', label: '知识点', align: 'left', width: '' },
        { prop: 'thisCount', label: '训练次数', align: 'center', width: '', isNum: true, color: '#2196F3' },
        { prop: ['thisRate', 'lastRate'], label: '得分率(%)', align: 'center', isRate: true },
        { prop: 'changeRate', label: '得分率变化(%)', align: 'center' }
      ],
    }
  },
  components: {
    SubjectCard, Mytable
  },
  computed: {
    ruodianArr () {
      let arr = this.info.filter(item => {
        return item.count < 2
      })
      return arr
    },
    ...mapState(['knowledageData']),
  },
  methods: {
    refresh () {
      this.$refs.SubjectCardRef.refresh()
    },
    getNumber (prop, value, row) {
      return value ? value : 0
    },
    async getInfo (id) {
      // this.$http({
      //   url: '/api/v1/report/stat_know',
      //   method: 'get',
      //   params: this.$getTimeParams(id, this.$parent.value1, this.$parent.value2)
      // }).then(res => {
      //   console.log('res', res)
      //   this.info = res.data.list.weak
      // })
      // const { data } = await getKnowledgeWeak(this.$getTimeParams(id, this.$parent.value1, this.$parent.value2))
      // this.info = data
      await this.$parent.getKnowledageData(id)
      this.info = this.knowledageData.data
    },
  }
}
</script>

<style lang="scss" scoped>
.cishu_warp {
  width: 95%;
  margin-left: 2.5%;
}
::v-deep .el-tabs__nav .is-top {
  margin: 0 auto;
  width: 120rem;
  text-align: center;
  font-size: 18rem;
}
::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
.colors {
  display: flex;
  flex-wrap: wrap;
  margin-left: 7%;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10rem;
    .yuan {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      margin-right: 10rem;
    }
  }
}
.content {
  margin-top: 20rem;
  margin-left: 4%;
  .title {
    font-size: 20rem;
    font-weight: bold;
    color: #000000;
    line-height: 43rem;
    height: 43rem;
  }
  .tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tab {
      padding: 0 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #333333;
      line-height: 33rem;
      font-size: 18rem;
      height: 33rem;
      background: #f6f6f6;
      border-radius: 15rem;
      margin-right: 10rem;
      margin-bottom: 15rem;
    }
  }
}

// ::v-deep .el-table th,
// .el-table tr {
//   background: #f2f2f2;
//   font-weight: bold;
//   font-size: 20rem;
// }

::v-deep .el-table {
  margin-top: 10rem;
  font-size: 24rem !important;
}
::v-deep .el-table th,
.el-table tr {
  background: #f2f2f2;
  font-weight: bold;
  font-size: 20rem;
}
</style>